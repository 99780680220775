<template>
  <div>
    <span>{{ $t('lbl_show') }}</span>
    <v-select
      v-model="perPageData"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
      @input="handleChangePerPage"
    />
    <span>{{ $t('lbl_entries') }}</span>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "ShowEntries",
  props: {
    perPage: {
      type: Number,
      default: 10
    },
    perPageOptions: {
      type: Array,
      default: () => [10, 25, 50]
    }
  },
  components: { vSelect },
  data() {
    return {
      perPageData: this.perPage
    };
  },
  methods: {
    handleChangePerPage(value) {
      this.$emit("setPerPage", value);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
